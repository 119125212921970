import { toast } from 'react-toastify';

import { useQuery, useSubscription } from '@apollo/client';

import { NotificationStats } from '../libs';
import { GET_NOTIFICATION_STATS_QUERY, SUBSCRIBE_TO_UPDATE_NOTIFICATION_STATS_SUBSCRIPTION } from '../libs/graphQL';

interface UseNotificationStatsQueryProps {
	kitchen: string;
	date: string;
}

const useNotificationStatsQuery = ({ kitchen, date }: UseNotificationStatsQueryProps) => {
	const { data, loading, refetch } = useQuery(GET_NOTIFICATION_STATS_QUERY, {
		variables: { kitchen, date },
		onError: (error) => {
			toast.error(`Error fetching notification stats: ${error.message}`);
		}
	});

	useSubscription(SUBSCRIBE_TO_UPDATE_NOTIFICATION_STATS_SUBSCRIPTION);

	return {
		notificationStats: data?.getNotificationStats as NotificationStats,
		notificationStatsLoading: loading,
		refetchNotificationStats: refetch
	};
};

export default useNotificationStatsQuery;

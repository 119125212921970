import { Auth, CognitoUser } from '@aws-amplify/auth';
import { ReactNode, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AuthState } from '../../libs';
import CustomSignIn from './CustomSignIn';

interface AuthGateProps {
	children: (_: { user: CognitoUser; signOut: () => void }) => ReactNode;
}

export default function AuthGate({ children }: AuthGateProps) {
	const [authState, setAuthState] = useState(AuthState.loading);
	const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);

	useEffect(() => {
		checkAuthState();
	}, []);

	const checkAuthState = async () => {
		try {
			const cognitoUser = await Auth.currentAuthenticatedUser({
				bypassCache: true
			});
			setCognitoUser(cognitoUser);
			setAuthState(AuthState.signedIn);
		} catch {
			setAuthState(AuthState.signIn);
		}
	};

	const signOut = async () => {
		try {
			await Auth.signOut();
			setAuthState(AuthState.signIn);
		} catch {
			toast.error('An error occured while logging you out please try again');
		}
	};

	switch (authState) {
		case AuthState.signedIn:
			return <>{children({ user: cognitoUser!, signOut })}</>;
		case AuthState.signIn:
			return <CustomSignIn setAuthState={setAuthState} cognitoUser={cognitoUser} setCognitoUser={setCognitoUser} />;
		default:
			<></>;
	}

	return <></>;
}

import '@aws-amplify/ui-react/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import { I18n } from 'aws-amplify';
import { format } from 'date-fns/fp';
import { createContext, useEffect, useMemo, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Flip, toast } from 'react-toastify';

import { useQuery, useSubscription } from '@apollo/client';
import { Box, LinearProgress, TableContainer } from '@mui/material';

import { CognitoUser as AuthenticatedCognitoUser } from '@aws-amplify/auth';
import { NotificationAlert, OfflineAlert, ProtectedRoute, Sidebar, TopBar } from './components';
import { useNotificationStatsQuery } from './hooks';
import useListPaginatedFoodsQuery from './hooks/useListPaginatedFoodsQuery';
import {
	Country,
	FoodComponent,
	KDSAppContext,
	KDSPermission,
	Kitchen,
	ListKitchensQuery,
	NotificationType,
	RoutePaths,
	Session,
	SingleFoodComponentWithBatches
} from './libs';
import { LIST_FOOD_COMPONENTS_QUERY, LIST_KITCHENS_QUERY, SUBSCRIBE_TO_UPDATE_SHOPPING_MENU_SUBSCRIPTION } from './libs/graphQL';
import { getActiveWorkingDayDate, getKitchenFromLocalStorage, parseCognitoUser, useDocumentMedia } from './libs/utils';
import {
	AssemblyCheck,
	ComponentsCheck,
	CustomerComplaints,
	DriverDispatch,
	FileManagement,
	Home,
	LeftoverMetrics,
	LockTimeChanges,
	LogisticsChanges,
	MealsStatus,
	PortioningDisplay,
	PreDispatchCheck,
	ProductivityMetrics,
	QualityMetrics,
	QualityTracking,
	RecipeGuideBook,
	ShortageDisplay,
	ShortageReporting,
	SpecialRequest,
	WastageTracking
} from './views';

export const AppContext = createContext<KDSAppContext>({
	isOffline: false,
	isSidebarOpened: true,
	isFilterOpen: false,
	user: {
		id: '',
		name: '',
		email: '',
		phoneNumber: '',
		permissions: [],
		country: Country.BH,
		kitchen: [Kitchen.BH001],
		group: ''
	},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	refetchFoods: () => { },
	setIsFilterOpen: () => false
});

export interface AppProps {
	signOut: () => void;
	user: AuthenticatedCognitoUser;
}

function App({ signOut, user }: AppProps) {
	const { isMobile, isTablet } = useDocumentMedia();
	const [isOpen, setIsOpen] = useState(true);
	const [kitchen, setKitchen] = useState<Kitchen>(getKitchenFromLocalStorage() as Kitchen);
	const [date, setDate] = useState(format('yyyy-MM-dd')(getActiveWorkingDayDate()));
	const [isOffline, setIsOffline] = useState(false);
	const [notificationReq, setNotificationReq] = useState({
		lockTimeNotificationReq: { cxRequest: 0, customerRequest: 0 },
		logisticsNotificationReq: { cxRequest: 0, customerRequest: 0 },
		shortagesNotificationReq: 0
	});
	const [shift, setShift] = useState<Session>(Session.all);

	const [openFilters, setOpenFilters] = useState(false);
	const { notificationStats, notificationStatsLoading, refetchNotificationStats } = useNotificationStatsQuery({
		kitchen,
		date
	});

	useEffect(() => {
		if (isMobile || isTablet) {
			setIsOpen(false);
		} else {
			setIsOpen(true);
		}
	}, [isMobile, isTablet]);

	const {
		loading: componentLoading,
		data: foodComponentsData,
		error: fcError
	} = useQuery(LIST_FOOD_COMPONENTS_QUERY, {
		variables: { kitchen, date }
	});

	const { foods, refetchFoods, foodsLoading } = useListPaginatedFoodsQuery({ kitchen, date, fetchAll: true });

	const { loading: kitchenSessionLoading, data: kdsKitchens } = useQuery<ListKitchensQuery>(LIST_KITCHENS_QUERY, {
		variables: { kitchen },
		onError: () => {
			toast.error('Error while loading Kitchen Sessions');
		},
		onCompleted: (data) => {
			const kSession = data.listKitchens?.data?.find((kitchenSession) => kitchenSession.id === kitchen)?.sessions;
			if (kSession === 'all') {
				setShift(Session.all);
			} else {
				setShift(Session.morning);
			}
		}
	});

	const kdsKitchen = useMemo(
		() => kdsKitchens?.listKitchens?.data?.find((KDSKitchen) => KDSKitchen.id === kitchen),
		[kdsKitchens, kitchen]
	);

	useEffect(() => {
		if (fcError) {
			toast.error('Error while loading food components');
			console.error(fcError);
		}
	}, [fcError]);

	useSubscription(SUBSCRIBE_TO_UPDATE_SHOPPING_MENU_SUBSCRIPTION);

	useEffect(() => {
		window.addEventListener('offline', () => {
			setIsOffline(true);
			toast.dismiss('toastify-online');
			toast(<OfflineAlert isOffline={true} />, {
				className: 'toastify-offline',
				hideProgressBar: true,
				closeOnClick: false,
				position: toast.POSITION.TOP_CENTER,
				closeButton: false,
				autoClose: false,
				toastId: 'toastify-offline'
			});
		});
		window.addEventListener('online', () => {
			setIsOffline(false);
			toast.update('toastify-offline', {
				render: <OfflineAlert isOffline={false} />,
				closeOnClick: true,
				autoClose: 3000,
				toastId: 'toastify-online',
				transition: Flip
			});
		});

		return () => {
			// eslint-disable-next-line unicorn/no-invalid-remove-event-listener
			window.removeEventListener('offline', () => {
				setIsOffline(false);
			});
			// eslint-disable-next-line unicorn/no-invalid-remove-event-listener
			window.removeEventListener('online', () => {
				setIsOffline(true);
			});
		};
	}, []);

	I18n.putVocabulariesForLanguage('en', {
		Username: 'Enter your email or phone number with country code',
		Password: 'Enter your password'
	});

	useMemo(() => {
		const { kitchenRequests, logisticsRequests, shortages } = notificationStats || {};
		if (logisticsRequests && logisticsRequests.cx && logisticsRequests.cx > notificationReq.logisticsNotificationReq.cxRequest) {
			toast.dismiss(NotificationType.logisticsChange);
			toast(<NotificationAlert type={NotificationType.logisticsChange} pendingReq={logisticsRequests.cx} />, {
				className: 'notification-alert',
				hideProgressBar: true,
				closeOnClick: true,
				position: isMobile ? toast.POSITION.BOTTOM_CENTER : toast.POSITION.BOTTOM_RIGHT,
				closeButton: true,
				autoClose: 10_000,
				toastId: NotificationType.logisticsChange
			});
		}

		if (kitchenRequests && kitchenRequests.cx && kitchenRequests.cx > notificationReq.lockTimeNotificationReq.cxRequest) {
			toast.dismiss(NotificationType.lockTimeChange);
			toast(<NotificationAlert type={NotificationType.lockTimeChange} pendingReq={kitchenRequests.cx} />, {
				className: 'notification-alert',
				hideProgressBar: true,
				closeOnClick: true,
				position: isMobile ? toast.POSITION.BOTTOM_CENTER : toast.POSITION.BOTTOM_RIGHT,
				closeButton: true,
				autoClose: 10_000,
				toastId: NotificationType.lockTimeChange
			});
		}
		if (shortages && shortages.kitchen && shortages.kitchen > notificationReq.shortagesNotificationReq) {
			toast.dismiss(NotificationType.shortage);
			toast(<NotificationAlert type={NotificationType.shortage} pendingReq={shortages.kitchen} />, {
				className: 'notification-alert',
				hideProgressBar: true,
				closeOnClick: true,
				position: isMobile ? toast.POSITION.BOTTOM_CENTER : toast.POSITION.BOTTOM_RIGHT,
				closeButton: true,
				autoClose: 10_000,
				toastId: NotificationType.shortage
			});
		}

		setNotificationReq((old) => ({
			...old,
			logisticsNotificationReq: {
				cxRequest: logisticsRequests?.cx || 0,
				customerRequest: logisticsRequests?.customer || 0
			},
			lockTimeNotificationReq: {
				cxRequest: kitchenRequests?.cx || 0,
				customerRequest: kitchenRequests?.customer || 0
			},
			shortagesNotificationReq: shortages?.kitchen || 0
		}));
	}, [date, kitchen, notificationStats]);

	useEffect(() => {
		setOpenFilters(false);
		refetchNotificationStats();
	}, [kitchen, date]);

	const foodComponents = (foodComponentsData?.listFoodComponents?.data || []) as FoodComponent[];

	const mappedUser = parseCognitoUser(user);

	return (
		<AppContext.Provider
			value={{
				isOffline: isOffline,
				isSidebarOpened: isOpen,
				isFilterOpen: openFilters,
				user: mappedUser,
				refetchFoods,
				setIsFilterOpen: () => setOpenFilters(false)
			}}
		>
			<Sidebar
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				signOut={signOut}
				date={date}
				setDate={setDate}
				kitchen={kitchen}
				setKitchen={setKitchen}
				user={mappedUser}
				notificationReq={notificationReq}
			/>
			<TopBar
				setIsOpen={setIsOpen}
				isOpen={isOpen}
				kitchen={kitchen}
				date={date}
				openFilters={openFilters}
				setOpenFilters={setOpenFilters}
			/>
			{(componentLoading || foodsLoading || kitchenSessionLoading || notificationStatsLoading) && (
				<Box sx={{ width: '100%' }}>
					<LinearProgress />
				</Box>
			)}
			<Box
				sx={{
					overflow: 'auto',
					height: '100%',
					width: { sm: isOpen ? `calc(100% - ${260}px)` : '100%' },
					ml: { sm: isOpen ? '260px' : '0' }
				}}
			>
				<TableContainer sx={{ display: 'inline-flex', height: '100%', width: '100%' }}>
					<Routes>
						<Route
							path={RoutePaths.componentsCheck}
							element={
								<ProtectedRoute
									component={ComponentsCheck}
									permission={KDSPermission.VIEW_COMPONENTS_CHECK}
									componentProps={{ kitchen, date }}
								/>
							}
						/>
						<Route
							path={RoutePaths.mealsStatus}
							element={
								<ProtectedRoute
									component={MealsStatus}
									permission={KDSPermission.VIEW_MEALS_STATUS}
									componentProps={{ shift, kitchen, foods, foodComponents }}
								/>
							}
						/>
						<Route
							path={RoutePaths.portioningDisplay}
							element={
								<ProtectedRoute
									component={PortioningDisplay}
									permission={KDSPermission.VIEW_PORTIONING_DISPLAY}
									componentProps={{
										shift,
										foods,
										foodComponents,
										kitchen,
										date
									}}
								/>
							}
						/>
						<Route
							path={RoutePaths.assemblyCheck}
							element={
								<ProtectedRoute
									component={AssemblyCheck}
									permission={KDSPermission.VIEW_ASSEMBLY_CHECK}
									componentProps={{ shift, foods, foodComponents, kitchen }}
								/>
							}
						/>
						<Route
							path={RoutePaths.wastageTracking}
							element={
								<ProtectedRoute
									component={WastageTracking}
									permission={KDSPermission.VIEW_WASTAGE_TRACKING}
									componentProps={{ foodComponents, date }}
								/>
							}
						/>
						<Route
							path={RoutePaths.customerComplaints}
							element={
								<ProtectedRoute
									component={CustomerComplaints}
									permission={KDSPermission.VIEW_CUSTOMER_COMPLAINTS}
									componentProps={{ kitchen, date }}
								/>
							}
						/>
						<Route
							path={RoutePaths.qualityTracking}
							element={
								<ProtectedRoute
									component={QualityTracking}
									permission={KDSPermission.VIEW_QUALITY_TRACKING}
									componentProps={{
										shift,
										date,
										kitchen,
										foodComponents: foodComponents as SingleFoodComponentWithBatches[]
									}}
								/>
							}
						/>
						<Route
							path={RoutePaths.shortageReporting}
							element={
								<ProtectedRoute
									component={ShortageReporting}
									permission={KDSPermission.VIEW_SHORTAGE_REPORTING}
									componentProps={{ foods, foodComponents }}
								/>
							}
						/>
						<Route
							path={RoutePaths.shortageDisplay}
							element={
								<ProtectedRoute
									component={ShortageDisplay}
									permission={KDSPermission.VIEW_SHORTAGE_DISPLAY}
									componentProps={{ kitchen, date, foods }}
								/>
							}
						/>
						<Route
							path={RoutePaths.leftoverMetrics}
							element={
								<ProtectedRoute
									component={LeftoverMetrics}
									permission={KDSPermission.VIEW_LEFTOVER_METRICS}
									componentProps={{ kitchen, date, shift, areas: kdsKitchen?.areas }}
								/>
							}
						/>
						<Route
							path={RoutePaths.preDispatchCheck}
							element={
								<ProtectedRoute
									component={PreDispatchCheck}
									permission={KDSPermission.VIEW_PRE_DISPATCH_CHECK}
									componentProps={{ kitchen, date, shift, areas: kdsKitchen?.areas }}
								/>
							}
						/>
						<Route
							path={RoutePaths.driverDispatch}
							element={
								<ProtectedRoute
									component={DriverDispatch}
									permission={KDSPermission.VIEW_DRIVER_DISPATCH}
									componentProps={{ kitchen, date }}
								/>
							}
						/>
						<Route
							path={RoutePaths.recipeGuideBook}
							element={
								<ProtectedRoute
									component={RecipeGuideBook}
									permission={KDSPermission.VIEW_RECIPE_GUIDEBOOK}
									componentProps={{ kitchen, date }}
								/>
							}
						/>
						<Route
							path={RoutePaths.lockTimeChanges}
							element={
								<ProtectedRoute
									component={LockTimeChanges}
									permission={KDSPermission.VIEW_LOCK_TIME_CHANGES}
									componentProps={{
										kitchen,
										date,
										foods,
										notificationReq: notificationReq.lockTimeNotificationReq.customerRequest
									}}
								/>
							}
						/>
						<Route
							path={RoutePaths.logisticsChanges}
							element={
								<ProtectedRoute
									component={LogisticsChanges}
									permission={KDSPermission.VIEW_LOGISTICS_CHANGES}
									componentProps={{
										kitchen,
										date,
										notificationReq: notificationReq.logisticsNotificationReq.customerRequest
									}}
								/>
							}
						/>
						<Route
							path={RoutePaths.fileManagement}
							element={
								<ProtectedRoute
									component={FileManagement}
									permission={KDSPermission.VIEW_FILE_MANAGEMENT}
									componentProps={{ kitchen, date }}
								/>
							}
						/>
						<Route
							path={RoutePaths.specialRequest}
							element={
								<ProtectedRoute
									component={SpecialRequest}
									permission={KDSPermission.VIEW_SPECIAL_REQUEST}
									componentProps={{ kitchen, date }}
								/>
							}
						/>
						<Route
							path={RoutePaths.qualityMetrics}
							element={
								<ProtectedRoute
									component={QualityMetrics}
									permission={KDSPermission.VIEW_QUALITY_METRICS}
									componentProps={{ kitchen, date, shift }}
								/>
							}
						/>
						<Route
							path={RoutePaths.productivityMetrics}
							element={
								<ProtectedRoute
									component={ProductivityMetrics}
									permission={KDSPermission.VIEW_QUALITY_METRICS}
									componentProps={{ kitchen, date, shift, foods }}
								/>
							}
						/>
						<Route path={RoutePaths.home} element={<ProtectedRoute component={Home} componentProps={{}} />} />
					</Routes>
				</TableContainer>
			</Box>
		</AppContext.Provider>
	);
}
export default App;

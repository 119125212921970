import { Box, OutlinedInput, Stack, Typography, useTheme } from '@mui/material';

import { Auth, CognitoHostedUIIdentityProvider, CognitoUser } from '@aws-amplify/auth';
import { Hub, HubCapsule } from '@aws-amplify/core';
import { LoadingButton } from '@mui/lab';
import parsePhoneNumber from 'libphonenumber-js';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import logo from '../../../assets/caloLogo';
import { caloTheme } from '../../../assets/themes/calo';
import { AuthState, ChallengeType } from '../../../libs';

interface CustomSignInProps {
	cognitoUser: CognitoUser | null;
	setCognitoUser: React.Dispatch<React.SetStateAction<CognitoUser | null>>;
	setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
}

const listener = (data: HubCapsule) => {
	if (data.payload.event === 'customState_failure') {
		toast.error('A User with the provided calo email does not exist please try again');
	}

	if (data.payload.event === 'signInWithRedirect_failure') {
		toast.error('An error occured while signing you in through your google account please try again');
	}
};

Hub.listen('auth', listener);

export default function CustomSignIn({ cognitoUser, setCognitoUser, setAuthState }: CustomSignInProps) {
	const theme = useTheme();

	const [phoneNumber, setPhoneNumber] = useState('');
	const [otp, setOtp] = useState('');
	const [codeSent, setCodeSent] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [resendIn, setResendIn] = useState(0);

	useEffect(() => {
		if (!resendIn) return;

		const token = setInterval(() => {
			setResendIn((prev) => prev - 1);
		}, 1000);

		return () => {
			clearInterval(token);
		};
	}, [resendIn]);

	const resendInPadded = useMemo(
		() =>
			resendIn &&
			`${Math.floor(resendIn / 60)
				.toString()
				.padStart(2, '0')}:${(resendIn % 60).toString().padStart(2, '0')}`,
		[resendIn]
	);

	const sendSignInOTP = async () => {
		try {
			if (!phoneNumber.length) {
				toast.error('Phone Number is required');
				return;
			}

			const parsedPhoneNumber = parsePhoneNumber(phoneNumber);

			if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
				toast.error('Please enter a valid phone number');
				return;
			}

			setIsLoading(true);

			const signInCognitoUser = await Auth.signIn(phoneNumber);

			setCognitoUser(signInCognitoUser);
			setCodeSent(true);

			const challengeParam = signInCognitoUser.challengeParam;

			if (challengeParam) {
				const resendIn = parseInt(challengeParam.resendIn);
				setResendIn(Number.isNaN(resendIn) ? 30 : resendIn);
			}

			return true;
		} catch (error) {
			const authError = error as unknown as Error;
			if ('code' in authError) {
				if (authError.code === 'UserNotFoundException') {
					toast.error('User not found please try again with a different phone number');
				} else if (authError.code === 'NotAuthorizedException') {
					toast.error('User is not authorized to sign in please try again with a different phone number');
				} else {
					toast.error('An error occured while signing you in please try again');
				}
			}
			return false;
		} finally {
			setIsLoading(false);
		}
	};

	const verifyOtp = async () => {
		try {
			setIsLoading(true);

			if (!otp.length) {
				toast.error('OTP is required');
				return;
			}

			if (!cognitoUser) {
				toast.error('Please enter your phone number before trying to log in');
				return;
			}

			await Auth.sendCustomChallengeAnswer(cognitoUser, otp.trim(), {
				challengeType: ChallengeType.OTP
			});

			const user = await Auth.currentAuthenticatedUser();

			setCognitoUser(user);
			setAuthState(AuthState.signedIn);
		} catch {
			toast.error('An error occured while verifying the OTP please enter the correct code');
		} finally {
			setIsLoading(false);
		}
	};

	const resendOtp = async () => {
		try {
			setIsLoading(true);
			if (!cognitoUser) {
				toast.error('Please enter your phone number before trying to log in');
				return;
			}

			const signedInCognitoUser = await Auth.sendCustomChallengeAnswer(cognitoUser, 'RESEND_OTP', {
				challengeType: ChallengeType.RESEND_OTP
			});

			const challengeParam = signedInCognitoUser.challengeParam;

			if (challengeParam) {
				const resendIn = parseInt(challengeParam.resendIn);
				setResendIn(Number.isNaN(resendIn) ? 30 : resendIn);
			}
		} catch {
			toast.error('An error occured while resending the OTP please try again');
		} finally {
			setIsLoading(false);
		}
	};

	async function handleSignInClick() {
		try {
			setIsLoading(true);

			const response = await Auth.federatedSignIn({
				provider: CognitoHostedUIIdentityProvider.Google
			});

			console.log(response);
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<Stack
			alignSelf={'center'}
			direction={'column'}
			alignItems={'center'}
			sx={{
				width: '30rem',
				[theme.breakpoints.down('sm')]: {
					width: '100%'
				}
			}}
		>
			<Box textAlign="center" padding={'20px'}>
				<img alt="Calo logo" src={`data:image/png;base64, ${logo}`} />
			</Box>
			<Stack
				direction={'column'}
				rowGap={'15px'}
				padding={'20px'}
				sx={{
					border: '1px solid black'
				}}
			>
				<Stack direction={'column'} rowGap={'15px'} textAlign="center">
					<Typography
						variant="h3"
						fontFamily={caloTheme.typography.fontFamily}
						fontWeight={'500'}
						textAlign="center"
						fontSize={'33px'}
					>
						Sign in to your account
					</Typography>
					<LoadingButton loading={isLoading} variant="outlined" onClick={handleSignInClick}>
						Google Sign In
					</LoadingButton>
					<Typography color={'#9D9D9D'} paddingBottom={'0px'}>
						Phone number must start with the country code and follow the format +xxxxxxxxxx
					</Typography>
				</Stack>
				<Stack direction="column" rowGap={'15px'}>
					<Stack direction="column" rowGap={'10px'}>
						<OutlinedInput
							value={phoneNumber}
							onChange={(e) => setPhoneNumber(e.target.value)}
							placeholder="Enter your phone number with country code"
						/>
						{!codeSent && (
							<LoadingButton loading={isLoading} variant="contained" onClick={sendSignInOTP}>
								Continue
							</LoadingButton>
						)}
					</Stack>
					{codeSent && (
						<Stack direction="column" rowGap={'10px'}>
							<OutlinedInput value={otp} onChange={(e) => setOtp(e.target.value)} placeholder="Code" />
							<LoadingButton loading={isLoading} variant="contained" onClick={verifyOtp}>
								Verify
							</LoadingButton>
							{resendIn ? (
								<Typography color={'#9D9D9D'} paddingBottom={'0px'}>
									Resend code in {resendInPadded}
								</Typography>
							) : (
								<Box>
									<Typography color={'#9D9D9D'} paddingBottom={'0px'}>
										Didn't Recive it? Retry
									</Typography>
									<LoadingButton loading={isLoading} variant="contained" onClick={resendOtp}>
										Resend
									</LoadingButton>
								</Box>
							)}
						</Stack>
					)}
				</Stack>
			</Stack>
			<Box textAlign="center" padding={'20px'}>
				<Typography color={'#57AE7F'}>&copy; 2022 Calo Inc.</Typography>
			</Box>
		</Stack>
	);
}

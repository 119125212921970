import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material/styles';
import Amplify from 'aws-amplify';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import App from './App';
import { caloTheme } from './assets/themes/calo';
import amplifyConfig from './configs/amplifyConfig';
import { GraphQLClient } from './libs/graphQL';
import AuthGate from './views/Authentication';

Amplify.configure(amplifyConfig);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<ApolloProvider client={GraphQLClient}>
		<Suspense fallback={<section />}>
			<BrowserRouter>
				<ThemeProvider theme={caloTheme}>
					<ToastContainer limit={3} />
					<AuthGate>
						{({ signOut, user }) => <App signOut={signOut} user={user} />}
					</AuthGate>
				</ThemeProvider>
			</BrowserRouter>
		</Suspense>
	</ApolloProvider>
);

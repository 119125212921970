/* eslint-disable @typescript-eslint/naming-convention */
export enum RoutePaths {
	home = '/home',
	componentsCheck = '/',
	portioningDisplay = '/portioning',
	mealsStatus = '/meals',
	wastageTracking = '/wastage-tracking',
	qualityTracking = '/quality-tracking',
	shortageReporting = '/shortage-reporting',
	shortageDisplay = '/shortage-display',
	assemblyCheck = '/assembly-check',
	preDispatchCheck = '/predispatch-check',
	recipeGuideBook = '/recipe-guide-book',
	lockTimeChanges = '/lock-time-changes',
	logisticsChanges = '/logistics-changes',
	driverDispatch = '/driver-dispatch',
	customerComplaints = '/customer-complaints',
	fileManagement = '/file-management',
	specialRequest = '/special-request',
	qualityMetrics = '/quality-metrics',
	productivityMetrics = '/productivity-metrics',
	leftoverMetrics = '/leftoverMetrics'
}

export enum FoodComponentStationTitle {
	commissary = 'Commissary',
	butchery = 'Butchery',
	hot = 'Hot',
	bakeryAndPastry = 'Bakery & Pastry',
	saucier = 'Saucier',
	roasting = 'Roasting',
	gardeManger = 'Garde Manger',
	portioning = 'Portioning',
	merchandise = 'Merchandise',
	other = 'Other'
}

export enum LeftoverAction {
	waste = 'Waste',
	useTomorrow = 'Use tomorrow',
	staffMeal = 'Staff meal',
	other = 'Other'
}

export enum PreDispatchCheckHeader {
	breakfast = 'Breakfast',
	lunchAndDinner = 'Lunch & Dinner',
	snack = 'Snack',
	addons = 'Add ons',
	dessert = 'Dessert',
	caloKids = 'Calo Kids'
}

export enum Shift {
	earlyMorning = 'Early Morning Shift',
	morning = 'Morning Shift',
	evening = 'Evening Shift',
	all = 'All Shifts'
}

export enum Actions {
	noActionTaken = 'No Action Taken',
	continueChilling = 'Continue Chilling',
	discard = 'Discard',
	employeeRetraining = 'Employee Retraining',
	equipmentMaintenance = 'Equipment Maintenance'
}

export enum WastageActions {
	noActionTaken = 'No Action Taken',
	waste = 'Waste',
	useTomorrow = 'Use Tomorrow',
	staffMeal = 'Staff Meal',
	other = 'Other'
}

export enum Page {
	assemblyCheck = 'assemblyCheck',
	portioningDisplay = 'portioningDisplay'
}

export enum FoodPortioningStatus {
	notReadyYet = 'Not ready yet',
	readyToAssemble = 'Ready to assemble',
	pauseAssembling = 'Pause Assembling',
	resumeAssembling = 'Resume Assembling',
	readyToPortion = 'Ready to portion',
	pausePortioning = 'Pause Portioning',
	resumePortioning = 'Resume Portioning',
	Assembling = 'Assembling',
	Portioning = 'Portioning',
	completed = 'Completed'
}

export enum FoodPortioningAction {
	pending = 'Pending',
	startAssembling = 'Start Assembling',
	pauseAssembling = 'Pause Assembling',
	resumeAssembling = 'Resume Assembling',
	finishAssembling = 'Finish Assembling',
	pausePortioning = 'Pause Portioning',
	resumePortioning = 'Resume Portioning',
	startPortioning = 'Start Portioning',
	finishPortioning = 'Finish Portioning',
	completed = 'Completed'
}

export enum FoodPortioningFoodType {
	notReadyFood = 'notReadyFood',
	startAssemblingFood = 'startAssemblingFood',
	pauseAssemblingFood = 'pauseAssemblingFood',
	resumeAssemblingFood = 'resumeAssemblingFood',
	startPortioningFood = 'startPortioningFood',
	pausePortioningFood = 'pausePortioningFood',
	resumePortioningFood = 'resumePortioningFood',
	completedFood = 'completedFood'
}

export enum NotificationType {
	lockTimeChange = 'Locktime Change',
	logisticsChange = 'Logistics Change',
	shortage = 'Shortage'
}

export enum ComplaintReason {
	HEALTH_RELATED_CONCERNS_COMPLAINTS = 'Health Related Concerns',
	COLD_SECTION_RELATED_ERRORS = 'Cold Section Related Errors',
	SPILLED_LIQUIDS_OR_FOOD_COMPLAINTS = 'Spilled Liquids or Food',
	DISPATCH_RELATED_ERRORS = 'Dispatch Related Errors',
	QUALITY_RELATED_COMPLAINTS = 'Quality Related Errors',
	FOREIGN_OBJECTS_FOUND = 'Foreign Objects Found',
	FOOD_RELATED_FOREIGN_OBJECTS_FOUND = 'Food Related Foreign Objects',
	NON_FOOD_RELATED_FOREIGN_OBJECTS_FOUND = 'Non-Food Related Foreign Objects'
}

export enum FileManagementType {
	meal = 'meals',
	customer = 'customer'
}

export enum DriverDispatchType {
	caloDrivers = 'caloDrivers',
	others = 'others'
}

export enum ExportMetricsType {
	meals = 'meals',
	components = 'components'
}

export enum LeftoverMetricsViewTypes {
	meals = 'meals',
	components = 'components'
}

export enum KDSPermission {
	VIEW_COMPONENTS_CHECK = 'v:cc',
	VIEW_PORTIONING_DISPLAY = 'v:pd',
	VIEW_MEALS_STATUS = 'v:ms',
	VIEW_WASTAGE_TRACKING = 'v:wt',
	VIEW_QUALITY_TRACKING = 'v:qt',
	VIEW_SHORTAGE_REPORTING = 'v:sr',
	VIEW_SHORTAGE_DISPLAY = 'v:sd',
	VIEW_ASSEMBLY_CHECK = 'v:ac',
	VIEW_PRE_DISPATCH_CHECK = 'v:pdc',
	VIEW_RECIPE_GUIDEBOOK = 'v:rg',
	VIEW_LOCK_TIME_CHANGES = 'v:ltc',
	VIEW_LOGISTICS_CHANGES = 'v:lc',
	VIEW_DRIVER_DISPATCH = 'v:dd',
	VIEW_CUSTOMER_COMPLAINTS = 'v:ccm',
	VIEW_FILE_MANAGEMENT = 'v:fm',
	VIEW_SPECIAL_REQUEST = 'v:srq',
	VIEW_QUALITY_METRICS = 'v:qm',
	VIEW_PRODUCTIVITY_METRICS = 'v:pm',
	VIEW_LEFTOVER_METRICS = 'v:lm'
}

export enum AuthState {
	loading = 'loading',
	signedIn = 'signedIn',
	signIn = 'signIn'
}

export enum ChallengeType {
	OTP = 'OTP',
	RESEND_OTP = 'RESEND_OTP'
}

import { useContext } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Button, Checkbox, Stack, Typography, useTheme } from '@mui/material';

import { AppContext } from '../../../../../App';
import { CombinedShortages, CookingStationName, FoodComponent, FoodComponentStationOperation } from '../../../../../libs';
import {
	BATCH_UPDATE_FOODS_PORTIONING_STATUS_MUTATION,
	UPDATE_FOOD_COMPONENT_STATUS_MUTATION
} from '../../../../../libs/graphQL';

type ActionButtonsProps = {
	foodComponent: FoodComponent;
	tableName: CookingStationName;
	statusAttributes: {
		isCheckable: boolean;
		lastUncheckedStation: string;
	};
} & (
	| {
			combinedShortages: CombinedShortages;
			isChecked: boolean;
			setIsCheckedState: React.Dispatch<React.SetStateAction<boolean>>;
	  }
	| { combinedShortages: undefined }
);

const ActionButtons = ({ foodComponent, tableName, statusAttributes, ...props }: ActionButtonsProps) => {
	const appContext = useContext(AppContext);
	const theme = useTheme();

	const [mutateFoodComponent, { loading }] = useMutation(UPDATE_FOOD_COMPONENT_STATUS_MUTATION);
	const [mutateFoodsPortioningStatus] = useMutation(BATCH_UPDATE_FOODS_PORTIONING_STATUS_MUTATION);
	const cookingStation = foodComponent.cookingStations?.find((station) => station.name === tableName);
	const allIntervals = cookingStation?.processTime?.intervals || [];

	const canStart = !cookingStation?.processTime && !cookingStation?.endTime;
	const canResume =
		cookingStation?.processTime?.startedAt && allIntervals.length > 0 && !allIntervals[allIntervals.length - 1].resumedAt;
	const canPause =
		cookingStation?.processTime?.startedAt && (allIntervals.length === 0 || allIntervals[allIntervals.length - 1].resumedAt);

	const handleFoodComponentStationAction = async (operation: FoodComponentStationOperation) => {
		await mutateFoodComponent({
			variables: {
				id: foodComponent.id,
				kitchen: foodComponent.kitchen,
				station: tableName,
				operation: operation,
				shortagesIds: props.combinedShortages?.ids || []
			},
			onCompleted: async () => {
				const lastStationName = foodComponent.cookingStations?.at(-1)?.name;
				if (lastStationName === tableName && operation === FoodComponentStationOperation.end && !props.combinedShortages) {
					await mutateFoodsPortioningStatus({
						variables: {
							kitchen: foodComponent.kitchen,
							foodsIds: foodComponent.foodsIds || []
						}
					});
				}
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	if (props.combinedShortages) {
		return (
			<Checkbox
				sx={{ mr: '30px' }}
				checked={props.isChecked}
				disabled={!statusAttributes.isCheckable || loading || appContext.isOffline || props.isChecked}
				onChange={(event) => {
					if (!props.isChecked) {
						props.setIsCheckedState(event.target.checked);
						handleFoodComponentStationAction(FoodComponentStationOperation.end);
					}
				}}
				inputProps={{ 'aria-label': 'controlled' }}
			/>
		);
	}

	if (canStart) {
		return (
			<Stack display={'flex'} flexDirection={'column'} sx={{ my: '-4px', alignItems: 'flex-end' }}>
				<Button
					onClick={() => handleFoodComponentStationAction(FoodComponentStationOperation.start)}
					disabled={!statusAttributes.isCheckable || loading || appContext.isOffline}
					variant="contained"
					sx={{
						width: '110px',
						height: '100%',
						color: theme.palette.white,
						border: '1px solid ' + theme.palette.primary500,
						borderRadius: '8px',
						textTransform: 'capitalize',
						'&:disabled': {
							borderColor: theme.palette.neutral200
						}
					}}
				>
					<Typography fontWeight="500" fontSize="13px">
						START NOW
					</Typography>
				</Button>
			</Stack>
		);
	}

	return (
		<Stack sx={{ my: '-4px', display: 'flex', flexDirection: 'row', gap: '12px', justifyContent: 'flex-end' }}>
			<Button
				variant="outlined"
				onClick={() =>
					handleFoodComponentStationAction(canPause ? FoodComponentStationOperation.pause : FoodComponentStationOperation.resume)
				}
				disabled={!statusAttributes.isCheckable || loading || appContext.isOffline}
				startIcon={canResume ? <PlayArrowIcon /> : <PauseCircleIcon sx={{ color: theme.palette.black }} />}
				sx={{
					width: '110px',
					height: '100%',
					color: theme.palette.black,
					backgroundColor: theme.palette.white,
					borderColor: theme.palette.black,
					borderRadius: '5px',
					textTransform: 'capitalize'
				}}
			>
				<Typography fontWeight="500" fontSize="13px">
					{canPause ? 'PAUSE' : 'RESUME'}
				</Typography>
			</Button>
			<Button
				variant="outlined"
				disabled={!statusAttributes.isCheckable || loading || appContext.isOffline}
				onClick={() => handleFoodComponentStationAction(FoodComponentStationOperation.end)}
				sx={{
					backgroundColor: theme.palette.white,
					borderColor: theme.palette.black,
					borderRadius: '5px',
					padding: 0,
					width: '38px',
					'&:disabled': {
						backgroundColor: theme.palette.white
					}
				}}
			>
				<CheckRoundedIcon sx={{ color: theme.palette.black }} />
			</Button>
		</Stack>
	);
};

export default ActionButtons;

import { v4 as uuid } from 'uuid';

import { CognitoUserAmplify } from '@aws-amplify/ui';

import { KDSPermission } from '../enums';
import { CognitoUser } from '../interfaces';
import { Country, Kitchen } from '../types';

export const parseCognitoUser = (user?: CognitoUserAmplify): CognitoUser => {
	const userAttributes = user?.attributes ?? user?.getSignInUserSession()?.getIdToken().payload ?? {};
	const permissions = userAttributes['custom:permissions'] ?? '';
	const group = userAttributes['custom:group'] ?? '';
	const userCountry = (userAttributes['custom:country'] as Country) ?? Country.BH;
	const filteredKitchens = Object.values(Kitchen).filter((k) => !k.includes('000'));
	const kitchenStaffPermissions = Object.values(KDSPermission).filter((p) => !p.includes(KDSPermission.VIEW_QUALITY_METRICS));

	return {
		id: userAttributes.sub ?? userAttributes.email ?? uuid(),
		name: userAttributes.name ?? '',
		email: userAttributes.email ?? '',
		phoneNumber: userAttributes.phone_number ?? '',
		country: userCountry,
		kitchen: userAttributes['custom:kitchen']
			? ((userAttributes['custom:kitchen'] as string).split(';') as Kitchen[])
			: [filteredKitchens.find((k) => k.includes(userCountry)) || Kitchen.BH001],
		group: group || '',
		permissions: permissions ? (permissions.split('|') as KDSPermission[]) : kitchenStaffPermissions
	};
};

export const getUserAttributes = (user: CognitoUser): Partial<CognitoUser> => {
	return {
		id: user.id,
		name: user.name,
		email: user.email
	};
};

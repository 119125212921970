const amplifyConfig = {
	Auth: {
		region: process.env.REACT_APP_AWS_REGION,
		identityPoolRegion: process.env.REACT_APP_IDENTITY_POOL_REGION,
		userPoolId: process.env.REACT_APP_USER_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
		oauth: {
			domain: process.env.REACT_APP_COGNITO_DOMAIN_URL,
			redirectSignIn: `${location.origin}/`,
			redirectSignOut: `${location.origin}/`,
			responseType: 'code'
		}
	}
};

export default amplifyConfig;

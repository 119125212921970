import { sortBy, uniq } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';

import EmptyMessage from '../../components/EmptyMessage';
import { Food, FoodComponent, Kitchen, Session, ShoppingMenu } from '../../libs';
import { GET_SHOPPING_MENU_QUERY } from '../../libs/graphQL';
import { useDocumentMedia } from '../../libs/utils/document';
import PortioningListMobile from './PortioningListMobile';
import PortioningTable from './PortioningTable';

interface PortioningDisplayProps {
	foods: Food[];
	shift: Session;
	foodComponents: FoodComponent[];
	kitchen: Kitchen;
	date: string;
}

const makeCustomMealRepresent = (customFood: Food[]) => {
	let count = 0;
	const componentIds: string[] = [];
	for (const f of customFood) {
		count += f.count ?? 0;
		componentIds.push(...(f.componentIds ?? []));
	}
	const { country, kitchen, date, sizes, deliveryTime, portioningStatus, type, brand, tags, id, portioningURL } = customFood[0];
	const food: Food = {
		id,
		name: {
			ar: 'Custom Meals',
			en: 'Custom Meals'
		},
		count,
		componentIds: uniq(componentIds),
		date,
		country,
		kitchen,
		sizes,
		isAssemblyRequired: false,
		deliveryTime,
		portioningStatus,
		isCustom: true,
		type,
		brand,
		tags,
		portioningURL,
		slug: 'custom-meals'
	};

	return food;
};

const PortioningDisplay = ({ shift, foods, kitchen, date }: PortioningDisplayProps) => {
	const { isMobile } = useDocumentMedia();
	const [filters, setFilters] = useState({
		showCustom: true,
		shift: shift
	});

	useEffect(() => {
		setFilters({ ...filters, shift: shift });
	}, [shift]);

	const { data: shoppingMenuData } = useQuery(GET_SHOPPING_MENU_QUERY, {
		variables: { id: date, kitchen },
		skip: !kitchen,
		refetchWritePolicy: 'overwrite'
	});

	const shoppingMenu: ShoppingMenu = shoppingMenuData?.getShoppingMenu;
	const shiftValues = filters.shift === Session.evening ? [Session.evening, Session.earlyMorning] : [filters.shift];

	const filteredFoods = filters.showCustom
		? foods.filter((food) => !food.isCustom && shiftValues.some((shift) => food.deliveryTime?.includes(shift)))
		: foods.filter((food) => shiftValues.some((shift) => food.deliveryTime?.includes(shift)));

	const data = useMemo(() => {
		const orderedFoods: Food[] = [];

		const shiftFilteredFoods = filters.shift
			? filteredFoods.filter((meal) => shiftValues.some((shift) => meal.deliveryTime?.includes(shift)))
			: filteredFoods;

		orderedFoods.push(...sortBy(shiftFilteredFoods, 'name.en'));
		const startPortioningFood: Food[] = [];
		const pausePortioningFood: Food[] = [];
		const resumePortioningFood: Food[] = [];
		const startAssemblingFood: Food[] = [];
		const pauseAssemblingFood: Food[] = [];
		const resumeAssemblingFood: Food[] = [];
		const notReadyFood: Food[] = [];
		const completedFood: Food[] = [];

		const customFood = foods.filter((food) => food.isCustom && food.deliveryTime?.includes(filters.shift));
		if (filters.showCustom && customFood.length > 0) {
			orderedFoods.push(makeCustomMealRepresent(sortBy(customFood, 'id')));
		}

		for (const food of orderedFoods) {
			const portioningStatus = food.portioningStatus?.find((s) => shiftValues.includes(s.session as Session));
			if (portioningStatus?.endTime) {
				completedFood.push(food);
			} else if (portioningStatus?.portioningTime) {
				const intervals = portioningStatus.portioningTime.intervals;
				if (!intervals) {
					resumePortioningFood.push(food);
				} else if (intervals[intervals.length - 1].resumedAt) {
					resumePortioningFood.push(food);
				} else {
					pausePortioningFood.push(food);
				}
			} else if (portioningStatus?.readyForPortioningTime || !portioningStatus?.endTime) {
				startPortioningFood.push(food);
			}
		}
		return {
			resumePortioningFood,
			pausePortioningFood,
			startPortioningFood,
			resumeAssemblingFood,
			pauseAssemblingFood,
			startAssemblingFood,
			notReadyFood,
			completedFood
		};
	}, [foods, filters, filteredFoods, foods]);

	if (isMobile) {
		return (
			<Box sx={{ width: '95%', display: 'flex', flexDirection: 'column' }}>
				{foods.length > 0 ? (
					<PortioningListMobile data={data} kitchen={kitchen} filters={filters} setFilters={setFilters} date={date} />
				) : (
					<EmptyMessage label={'No data'} />
				)}
			</Box>
		);
	} else {
		return (
			<Box sx={{ width: '98%', minHeight: '100vh' }}>
				{foods.length > 0 ? (
					<PortioningTable
						data={data}
						kitchen={kitchen}
						filters={filters}
						setFilters={setFilters}
						shoppingMenu={shoppingMenu}
						date={date}
					/>
				) : (
					<EmptyMessage label={'no data'} />
				)}
			</Box>
		);
	}
};
export default PortioningDisplay;

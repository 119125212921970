import { gql } from '@apollo/client';
import {
	BAGGING_DISPATCH_FIELDS,
	BAGGING_SESSION_FIELDS,
	CHEF_FIELDS,
	COMPLAINT_FIELDS,
	COMPONENT_BATCH_FIELDS,
	DELETE_COMPONENT_BATCH_RESPONSE_FIELDS,
	DELIVERY_TICKET_FIELDS,
	DRIVER_DISPATCH_FIELDS,
	EXPORT_DOCUMENTS_RESPONSE_FIELDS,
	EXPORT_FILE_RESPONSE_FIELDS,
	FOOD_COMPONENT_FIELDS,
	FOOD_FIELDS,
	NOTIFICATION_STATS_FIELDS,
	SCAN_SHOPPING_DELIVERY_RESPONSE_FIELDS,
	SHOPPING_MENU_FIELDS
} from './fragments';

export const APPROVE_DELIVERY_TICKET_MUTATION = gql`
	${DELIVERY_TICKET_FIELDS}
	mutation ApproveDeliveryTicket($id: String, $type: DeliveryTicketType, $kitchen: Kitchen, $user: UserInput) {
		approveDeliveryTicket(id: $id, type: $type, kitchen: $kitchen, user: $user) {
			...DeliveryTicketFields
		}
	}
`;

export const BATCH_CREATE_COMPONENT_SHORTAGE_MUTATION = gql`
	${FOOD_COMPONENT_FIELDS}
	mutation BatchCreateComponentShortage($input: [ComponentShortageInput], $kitchen: Kitchen, $shift: String) {
		batchCreateComponentShortage(input: $input, kitchen: $kitchen, shift: $shift) {
			...FoodComponentFields
		}
	}
`;

export const BATCH_UPDATE_FOODS_PORTIONING_STATUS_MUTATION = gql`
	${FOOD_FIELDS}
	mutation BatchUpdateFoodsPortioningStatus($kitchen: Kitchen, $foodsIds: [String]) {
		batchUpdateFoodsPortioningStatus(kitchen: $kitchen, foodsIds: $foodsIds) {
			...FoodFields
		}
	}
`;

export const CREATE_CHEF_MUTATION = gql`
	${CHEF_FIELDS}
	mutation CreateChef($data: ChefInput) {
		createChef(data: $data) {
			...ChefFields
		}
	}
`;

export const CREATE_COMPONENT_BATCH_MUTATION = gql`
	${COMPONENT_BATCH_FIELDS}
	mutation CreateComponentBatch($data: ComponentBatchInput) {
		createComponentBatch(data: $data) {
			...ComponentBatchFields
		}
	}
`;

export const DELETE_CHEF_MUTATION = gql`
	${CHEF_FIELDS}
	mutation DeleteChef($id: String!, $kitchen: Kitchen!) {
		deleteChef(id: $id, kitchen: $kitchen) {
			...ChefFields
		}
	}
`;

export const DELETE_COMPONENT_BATCH_MUTATION = gql`
	${DELETE_COMPONENT_BATCH_RESPONSE_FIELDS}
	mutation DeleteComponentBatch($id: String, $kitchen: String) {
		deleteComponentBatch(id: $id, kitchen: $kitchen) {
			...DeleteComponentBatchResponseFields
		}
	}
`;

export const DELIVERY_TICKET_CREATED_MUTATION = gql`
	${DELIVERY_TICKET_FIELDS}
	mutation DeliveryTicketCreated($id: String, $kitchen: Kitchen) {
		deliveryTicketCreated(id: $id, kitchen: $kitchen) {
			...DeliveryTicketFields
		}
	}
`;

export const EXPORT_COMPLAINT_LIST_MUTATION = gql`
	${EXPORT_FILE_RESPONSE_FIELDS}
	mutation ExportComplaintList($filters: ComplaintsFiltersInput) {
		exportComplaintList(filters: $filters) {
			...ExportFileResponseFields
		}
	}
`;

export const EXPORT_COMPONENT_BATCH_MUTATION = gql`
	${EXPORT_FILE_RESPONSE_FIELDS}
	mutation ExportComponentBatch($kitchen: Kitchen, $date: RangeInput, $shift: String) {
		exportComponentBatch(kitchen: $kitchen, date: $date, shift: $shift) {
			...ExportFileResponseFields
		}
	}
`;

export const EXPORT_DOCUMENTS_MUTATION = gql`
	${EXPORT_DOCUMENTS_RESPONSE_FIELDS}
	mutation ExportDocuments($ids: [String]!, $docTypes: [DocsType]!, $day: String, $kitchen: Kitchen, $shift: String) {
		exportDocuments(ids: $ids, docTypes: $docTypes, day: $day, kitchen: $kitchen, shift: $shift) {
			...ExportDocumentsResponseFields
		}
	}
`;

export const EXPORT_FOOD_COMPONENT_SHORTAGE_MUTATION = gql`
	${EXPORT_FILE_RESPONSE_FIELDS}
	mutation ExportFoodComponentShortage($kitchen: Kitchen, $startDate: String, $endDate: String) {
		exportFoodComponentShortage(kitchen: $kitchen, startDate: $startDate, endDate: $endDate) {
			...ExportFileResponseFields
		}
	}
`;

export const EXPORT_FOOD_COMPONENT_STATUS_MUTATION = gql`
	${EXPORT_FILE_RESPONSE_FIELDS}
	mutation ExportFoodComponentStatus($kitchen: Kitchen, $startDate: String, $endDate: String) {
		exportFoodComponentStatus(kitchen: $kitchen, startDate: $startDate, endDate: $endDate) {
			...ExportFileResponseFields
		}
	}
`;

export const EXPORT_FOOD_COMPONENT_WASTAGE_MUTATION = gql`
	${EXPORT_FILE_RESPONSE_FIELDS}
	mutation ExportFoodComponentWastage($kitchen: Kitchen, $startDate: String, $endDate: String) {
		exportFoodComponentWastage(kitchen: $kitchen, startDate: $startDate, endDate: $endDate) {
			...ExportFileResponseFields
		}
	}
`;

export const EXPORT_FOOD_LABELS_MUTATION = gql`
	${EXPORT_DOCUMENTS_RESPONSE_FIELDS}
	mutation ExportFoodLabels($input: ExportFoodLabelsReq) {
		exportFoodLabels(input: $input) {
			...ExportDocumentsResponseFields
		}
	}
`;

export const EXPORT_FOOD_PORTIONING_STATUS_MUTATION = gql`
	${EXPORT_FILE_RESPONSE_FIELDS}
	mutation ExportFoodPortioningStatus($kitchen: Kitchen, $startDate: String, $endDate: String, $showCustom: Boolean) {
		exportFoodPortioningStatus(kitchen: $kitchen, startDate: $startDate, endDate: $endDate, showCustom: $showCustom) {
			...ExportFileResponseFields
		}
	}
`;

export const EXPORT_MEAL_INVOICES_MUTATION = gql`
	${EXPORT_DOCUMENTS_RESPONSE_FIELDS}
	mutation ExportMealInvoices($id: String, $kitchen: Kitchen, $shoppingMenuFoodSlug: String) {
		exportMealInvoices(id: $id, kitchen: $kitchen, shoppingMenuFoodSlug: $shoppingMenuFoodSlug) {
			...ExportDocumentsResponseFields
		}
	}
`;

export const EXPORT_QUALITY_METRICS_MUTATION = gql`
	${EXPORT_FILE_RESPONSE_FIELDS}
	mutation ExportQualityMetrics(
		$startDate: String
		$endDate: String
		$kitchen: Kitchen
		$shift: Session
		$type: ExportMetricsTypeEnum
		$stations: [CookingStationName]
	) {
		exportQualityMetrics(
			startDate: $startDate
			endDate: $endDate
			kitchen: $kitchen
			shift: $shift
			type: $type
			stations: $stations
		) {
			...ExportFileResponseFields
		}
	}
`;

export const GET_IMAGE_UPLOAD_URL_MUTATION = gql`
	${EXPORT_FILE_RESPONSE_FIELDS}
	mutation GetImageUploadURL($path: String) {
		getImageUploadURL(path: $path) {
			...ExportFileResponseFields
		}
	}
`;

export const MODIFY_BAGGING_DISPATCH_MUTATION = gql`
	${BAGGING_DISPATCH_FIELDS}
	mutation ModifyBaggingDispatch($id: String, $input: BaggingDispatchInput!) {
		modifyBaggingDispatch(id: $id, input: $input) {
			...BaggingDispatchFields
		}
	}
`;

export const MODIFY_BAGGING_SESSION_MUTATION = gql`
	${BAGGING_SESSION_FIELDS}
	mutation ModifyBaggingSession($id: String, $input: BaggingSessionInput!) {
		modifyBaggingSession(id: $id, input: $input) {
			...BaggingSessionFields
		}
	}
`;

export const REJECT_DELIVERY_TICKET_MUTATION = gql`
	${DELIVERY_TICKET_FIELDS}
	mutation RejectDeliveryTicket($id: String, $type: DeliveryTicketType, $kitchen: Kitchen, $user: UserInput) {
		rejectDeliveryTicket(id: $id, type: $type, kitchen: $kitchen, user: $user) {
			...DeliveryTicketFields
		}
	}
`;

export const RESOLVE_COMPLAINT_MUTATION = gql`
	${COMPLAINT_FIELDS}
	mutation ResolveComplaint($id: String, $status: ComplaintStatus, $kitchen: Kitchen, $user: UserInput) {
		resolveComplaint(id: $id, status: $status, kitchen: $kitchen, user: $user) {
			...ComplaintFields
		}
	}
`;

export const SCAN_SHOPPING_DELIVERY_MUTATION = gql`
	${SCAN_SHOPPING_DELIVERY_RESPONSE_FIELDS}
	mutation ScanShoppingDelivery($id: String, $kitchen: Kitchen) {
		scanShoppingDelivery(id: $id, kitchen: $kitchen) {
			...ScanShoppingDeliveryResponseFields
		}
	}
`;

export const START_BAGGING_SESSION_MUTATION = gql`
	${BAGGING_SESSION_FIELDS}
	mutation StartBaggingSession($input: BaggingSessionInput!) {
		startBaggingSession(input: $input) {
			...BaggingSessionFields
		}
	}
`;

export const UPDATE_CHEF_MUTATION = gql`
	${CHEF_FIELDS}
	mutation UpdateChef($id: String, $input: ChefInput) {
		updateChef(id: $id, input: $input) {
			...ChefFields
		}
	}
`;

export const UPDATE_COMPONENT_BATCH_MUTATION = gql`
	${COMPONENT_BATCH_FIELDS}
	mutation UpdateComponentBatch($id: String, $input: ComponentBatchInput) {
		updateComponentBatch(id: $id, input: $input) {
			...ComponentBatchFields
		}
	}
`;

export const UPDATE_DELIVERY_TICKETS_DOWNLOADED_AT_MUTATION = gql`
	${DELIVERY_TICKET_FIELDS}
	mutation UpdateDeliveryTicketsDownloadedAt($ids: [String]!, $kitchen: Kitchen!) {
		updateDeliveryTicketsDownloadedAt(ids: $ids, kitchen: $kitchen) {
			...DeliveryTicketFields
		}
	}
`;

export const UPDATE_DRIVER_DISPATCH_MUTATION = gql`
	${DRIVER_DISPATCH_FIELDS}
	mutation UpdateDriverDispatch($id: String, $input: DriverDispatchInput) {
		updateDriverDispatch(id: $id, input: $input) {
			...DriverDispatchFields
		}
	}
`;

export const UPDATE_EXTRA_MEALS_MUTATION = gql`
	${FOOD_FIELDS}
	mutation UpdateExtraMeals($kitchen: Kitchen, $data: [UpdateExtraMealsInput]) {
		updateExtraMeals(kitchen: $kitchen, data: $data) {
			...FoodFields
		}
	}
`;

export const UPDATE_FCWASTAGE_INPUTS_MUTATION = gql`
	${FOOD_COMPONENT_FIELDS}
	mutation UpdateFCWastageInputs($kitchen: Kitchen, $date: String, $id: String, $inputs: FCWastageInputs) {
		updateFCWastageInputs(kitchen: $kitchen, date: $date, id: $id, inputs: $inputs) {
			...FoodComponentFields
		}
	}
`;

export const UPDATE_FOOD_COMPONENT_STATUS_MUTATION = gql`
	${FOOD_COMPONENT_FIELDS}
	mutation UpdateFoodComponentStatus(
		$kitchen: Kitchen!
		$id: String!
		$station: CookingStationName!
		$operation: FoodComponentStationOperation!
		$shortagesIds: [String]
		$shift: String
	) {
		updateFoodComponentStatus(
			kitchen: $kitchen
			id: $id
			station: $station
			operation: $operation
			shortagesIds: $shortagesIds
			shift: $shift
		) {
			...FoodComponentFields
		}
	}
`;

export const UPDATE_FOOD_PORTIONING_STATUS_MUTATION = gql`
	${FOOD_FIELDS}
	mutation UpdateFoodPortioningStatus(
		$kitchen: Kitchen!
		$id: String!
		$session: String
		$operation: UpdateFoodPortioningStatusOperation!
	) {
		updateFoodPortioningStatus(kitchen: $kitchen, id: $id, session: $session, operation: $operation) {
			...FoodFields
		}
	}
`;

export const UPDATE_NOTIFICATION_STATS_MUTATION = gql`
	${NOTIFICATION_STATS_FIELDS}
	mutation UpdateNotificationStats($date: String, $kitchen: Kitchen, $updates: [NotificationUpdateInput]) {
		updateNotificationStats(date: $date, kitchen: $kitchen, updates: $updates) {
			...NotificationStatsFields
		}
	}
`;

export const UPDATE_SHOPPING_MENU_MUTATION = gql`
	${SHOPPING_MENU_FIELDS}
	mutation UpdateShoppingMenu($id: String, $kitchen: Kitchen, $data: UpdateShoppingMenuInput) {
		updateShoppingMenu(id: $id, kitchen: $kitchen, data: $data) {
			...ShoppingMenuFields
		}
	}
`;

export const VERIFY_COMPONENT_MUTATION = gql`
	${FOOD_COMPONENT_FIELDS}
	mutation VerifyComponent($input: VerifyComponentInput, $kitchen: Kitchen) {
		verifyComponent(input: $input, kitchen: $kitchen) {
			...FoodComponentFields
		}
	}
`;

import { Stack } from '@mui/material';
import { round } from 'lodash-es';
import SummaryBox from '../../Common/SummaryBox';
import { DailyDispatchLeftoverStats } from '../MealsView';

interface TopSectionProps {
	dailyDispatchLeftoverStats: DailyDispatchLeftoverStats;
}

const TopSection = ({ dailyDispatchLeftoverStats }: TopSectionProps) => {
	return (
		<Stack sx={{ width: '100%' }} direction={'row'} spacing={'10px'} marginBottom={'35px'}>
			<SummaryBox
				topText="AMOUNT OF LEFTOVERS"
				bottomLeftText={`${dailyDispatchLeftoverStats.totalLeftovers}`}
				bottomRightText="meals"
			/>
			<SummaryBox
				topText="TOTAL COST OF LEFTOVERS"
				bottomLeftText={`${dailyDispatchLeftoverStats.currency} ${round(dailyDispatchLeftoverStats.costOfLeftovers, 2)}`}
			/>
			<SummaryBox
				topText="COST OF FOOD LEFTOVERS"
				bottomLeftText={`${dailyDispatchLeftoverStats.currency} ${round(dailyDispatchLeftoverStats.foodCostOfLeftovers, 2)}`}
			/>
			<SummaryBox
				topText="COST OF PACKAGING LEFTOVERS"
				bottomLeftText={`${dailyDispatchLeftoverStats.currency} ${round(dailyDispatchLeftoverStats.packagingCostOfLeftovers, 2)}`}
			/>
		</Stack>
	);
};

export default TopSection;
